<template>
  <section id="newsletter" class="newsletter">
    <div class="container text-center">
      <h2>
        {{ $t('common.newsletter_title') }}
      </h2>
      <p class="inline-block w-full">
        {{ $t('common.newsletter_line1') }}
      </p>
      <form id="newsletter_form" @submit.prevent="onSubmit">
        <div class="newsletter_input-group">
          <input
            id="newsletter_email"
            v-model="email"
            name="email"
            type="email"
            data-cons-subject="email"
            autocomplete="email"
            required
            :disabled="iSsending"
            class="rtl:rounded-r-md ltr:rounded-l-md"
            :placeholder="$t('common.email')"
          />
          <Btn
            id="newsletter-submit"
            :disabled="iSsending"
            type="submit"
            size="xl"
            class="rounded-s-none"
          >
            {{ $t('common.submit') }}
          </Btn>
        </div>
        <div class="newsletter_terms">
          <label>
            <input
              id="newsletter_tos"
              v-model="accept_terms"
              type="checkbox"
              required
              class="rounded-sm"
              data-cons-preference="terms-and-conditions"
            />
            <!-- eslint-disable vue/no-v-html -->
            <span
              class="text-black"
              v-html="
                $t('common.i_agree_with_the_terms_and_conditions').replace(
                  'legal',
                  $i18nPath('/terms')
                )
              "
            />
            <!-- eslint-enable -->
          </label>
          <input
            type="hidden"
            name="newsletter"
            value="newsletter"
            data-cons-preference="newsletter"
          />
        </div>
        <ErrorBag :errors="errorsObj" class="error-bag" />

        <div v-if="VueHcaptcha">
          <component
            :is="VueHcaptcha"
            ref="invisibleHcaptcha"
            size="invisible"
            :sitekey="$config.HCAPTCHA_SITE_KEY"
            theme="dark"
            :language="store.locale"
            @verify="onVerify"
            @expired="onExpire"
            @closed="onExpire"
            @challengeExpired="onExpire"
            @error="onError"
          />
        </div>
      </form>
    </div>
  </section>
</template>

<script setup>
// import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";/
import { useMainStore } from '@/store/index'
import { useTranslation } from 'i18next-vue'

const nuxtApp = useNuxtApp()
const store = useMainStore()
const { t } = useTranslation()

let VueHcaptcha = shallowRef(null)
const email = ref(null)
const accept_terms = ref(false)
const iSsending = ref(false)
const invisibleHcaptcha = ref(null)
let errorsObj = reactive({})
const $i18nPath = nuxtApp.$i18nPath
const $config = nuxtApp.$config.public

onMounted(() => {
  if (import.meta.browser) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!VueHcaptcha.value) {
            VueHcaptcha.value = defineAsyncComponent(() =>
              import('@hcaptcha/vue3-hcaptcha')
            )
          }
        }
      })
    })

    observer.observe(document.getElementById('newsletter'))
  }
})

function onVerify(token, ekey) {
  useAPI('/api/front/subscriber', {
    method: 'POST',
    body: {
      email: email.value,
      accept_terms: accept_terms.value,
      'h-captcha-response': token,
    },
  })
    .then((response) => {
      nuxtApp.$toast.success(t('common.newsletter_thanks'))
      email.value = null
      accept_terms.value = false
      iSsending.value = false

      window._iub.cons_instructions.push([
        'submit',
        {
          writeOnLocalStorage: false,
          form: {
            selector: document.getElementById('newsletter_form'),
          },
          consent: {
            legal_notices: [
              {
                identifier: 'privacy_policy',
              },
              {
                identifier: 'cookie_policy',
              },
              {
                identifier: 'term',
              },
            ],
          },
        },
        {
          success(response) {
            // console.log(response)
          },
          error(response) {
            console.warning(response)
          },
        },
      ])
    })
    .catch((error) => {
      console.error(error, 'err')
      errorsObj = error.response.data.errors
      iSsending.value = false
      accept_terms.value = false
    })
}

function onExpire() {
  // this.$toast.error('Captcha expired')
  console.log('[vue-htcapcha] Expired token')
  iSsending.value = false
}

function onError() {
  console.log('[vue-htcapcha] Failed to load hcaptcha')
  iSsending.value = false
}

function onSubmit() {
  if (iSsending.value) {
    return
  }
  iSsending.value = true
  errorsObj = {}

  if (invisibleHcaptcha.value) {
    invisibleHcaptcha.value.execute()
  }
}
</script>

<style lang="scss">
.newsletter_terms {
  @apply text-black font-light flex items-center;

  span a {
    @apply text-[#807e7e] no-underline transition-all bg-no-repeat;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 100%;
    transition: background-size 0.4s cubic-bezier(0, 0.5, 0, 1);
    background-size: 0 2px;

    &:hover {
      @apply text-[#333232] no-underline;
      background-size: 100% 2px;
    }
  }
}

.newsletter {
  background-color: white;
  padding: 3rem 0;

  h2 {
    @apply inline-block font-bold text-4xl mx-0 my-2;
  }

  p {
    @apply text-primary inline-block text-[35px] leading-none font-bold;
  }

  form {
    width: 100%;
    max-width: 500px;
    margin: 2rem auto 0;

    .newsletter_input-group {
      display: flex;
      position: relative;
      flex-grow: 1;
      align-items: stretch;
      margin-bottom: 0.5rem;

      input {
        display: block;
        font-size: 20px;
        padding: 0.75rem;
        width: 100%;
        border: 1px solid #d1d5db;

        &:focus {
          outline: none;
        }
      }

      button {
        @apply bg-primary text-xl py-0 text-white w-20 px-4 border-none;
        transition: opacity 0.5s;

        &:hover {
          opacity: 0.75;
        }
      }
    }

    .newsletter_terms {
      label {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        font-size: 1.2rem;
        padding: 0.5rem;
        width: 100%;
        border-width: 1px;
        border-color: transparent;
        cursor: pointer;

        input {
          width: 17px;
          height: 17px;
          cursor: pointer;
        }
      }
    }

    .error-bag {
      max-width: 400px;
      margin: 1rem auto 0;
    }
  }
}

@media only screen and (min-width: 768px) {
  .newsletter {
    padding: 4rem 0;

    h2 {
      display: block;
      font-size: 50px;
    }

    p {
      font-size: 40px;
    }
  }
}
</style>
