<template>
  <div id="offline" v-if="$nuxt.isOffline" class="flex items-center shadow">
    <strong>
      {{ $t('common.offline') }}
    </strong>
    <Spinner small label="offline" class="ml-auto animate-spin" />
  </div>
</template>

<script setup>
</script>
<style lang="scss" scoped>
#offline {
  @apply bg-[#d60a1e] text-white py-[0.75rem] px-[1.25rem] fixed inset-x-0 top-0 w-full z-[999999];
}
</style>
