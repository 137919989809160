<template>
  <header
    :class="navClasses"
    class="inset-x-0 z-[999] hidden w-full transition-all lg:block"
  >
    <div class="transition-all">
      <div
        :class="[
          state.scrollPosition >= 80 && !state.isProductPage
            ? 'bg-white'
            : 'bg-transparent',
          'h-[46px] relative z-[999]',
        ]"
      >
        <div class="container flex items-center justify-between h-full">
          <!-- Locale selector -->
          <LocaleSelector
            :color="color"
            :name="state.isProductPage ? 'headerproduct' : 'header'"
            align="left"
          />

          <!-- User selector -->
          <UserDropdown
            v-if="store.enableShop"
            :color="
              state.scrollPosition >= 40 && !state.isProductPage
                ? '#0f0f0f'
                : color
            "
            :text-color="
              state.scrollPosition >= 40 && !state.isProductPage
                ? 'text-[#0f0f0f]'
                : 'text-white'
            "
          />
        </div>
      </div>
      <div class="relative">
        <div class="container grid justify-between grid-cols-2 gap-4 h-[74px]">
          <nav class="flex items-center space-s-6">
            <!-- Models -->

            <FlyoutMenu v-if="products && products.length > 0" is-product>
              <a
                :class="[dynamicLinkClass, newTextClass]"
                v-text="$t('common.bicycles')"
                class="cursor-pointer nav-link hover-effect rtl:ml-6"
              />
            </FlyoutMenu>

            <FlyoutMenu
              v-if="
                categories && categories.length > 0 && store.country !== 'us'
              "
            >
              <a
                :class="[dynamicLinkClass, newTextClass]"
                class="cursor-pointer nav-link hover-effect rtl:ml-6"
              >
                {{ $t('common.accessories') }}
              </a>
            </FlyoutMenu>
            <!-- Warranty -->
            <!-- <nuxt-link
              :to="$i18nPath('/warranty')"
              :class="isProductPage ? 'nav-product' : ''"
              :prefetch="false"
              v-text="$t('common.warranty')"
              class="nav-link hover-effect rtl:ml-6 "
            /> -->
            <!-- Shop online -->
            <nuxt-link
              v-if="store.enableShop"
              :to="$i18nPath('/search')"
              :class="[dynamicLinkClass, newTextClass]"
              :prefetch="false"
              class="nav-link hover-effect rtl:ml-6"
            >
              {{ $t('common.shop_online') }}
            </nuxt-link>
          </nav>

          <!-- Logo -->
          <nuxt-link
            :to="$i18nPath('/')"
            class="absolute transition-all transform -translate-s-1/2 rtl:left-[50%] top-[15px]"
            :class="store.locale !== 'ar' ? 'start-1/2' : ''"
          >
            <img
              :class="
                state.scrollPosition >= 40 && !state.isProductPage
                  ? 'w-[55px]'
                  : 'w-[60px]'
              "
              src="https://cdn.bbike-cdn.com.cn/benelli.bike/static/common/redlogo.png"
              alt="logo"
              class="transition-all"
            />
          </nuxt-link>
          <nav
            :class="
              state.scrollPosition >= 40 && !state.isProductPage
                ? 'my-[20px]'
                : 'my-[30px]'
            "
            class="flex items-center justify-end space-s-6"
          >
            <!-- Stores -->
            <nuxt-link
              v-if="store.enableShop"
              :to="$i18nPath('/stores')"
              class="nav-link hover-effect rtl:ml-6"
              :class="[dynamicLinkClass, newTextClass]"
              :prefetch="false"
            >
              {{ $t('common.stores') }}
            </nuxt-link>

            <!-- News -->
            <nuxt-link
              v-if="currentSite && currentSite.news"
              :to="$i18nPath('/news')"
              class="nav-link hover-effect rtl:ml-6"
              :class="[dynamicLinkClass, newTextClass]"
              :prefetch="false"
            >
              {{ $t('common.news') }}
            </nuxt-link>
            <!-- Contact us -->
            <nuxt-link
              :to="$i18nPath('/contact')"
              class="nav-link hover-effect rtl:!ml-1"
              :class="[dynamicLinkClass, newTextClass]"
              :prefetch="false"
            >
              {{ $t('common.contact_us') }}
            </nuxt-link>

            <!-- <nuxt-link
              :to="$i18nPath('/how-to-buy')"
              :class="[isProductPage ? 'nav-product' : '',newTextClass]"
              :prefetch="false"
              v-text="$t('common.how_to_buy')"
              class="nav-link hover-effect"
            /> -->
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>
<script setup>
import { useModal } from 'vue-final-modal'
import { useMainStore } from '@/store/index'

const nuxtApp = useNuxtApp()
const store = useMainStore()
const route = useRoute()
const router = useRouter()

const $i18nPath = nuxtApp.$i18nPath
const color = ref('white')
const state = reactive({
  isStorePage: false,
  isProductPage: false,
  scrollPosition: 0,
})

state.isStorePage = !!(route.name == 'country-stores')

const navClasses = computed(() => {
  const classes = []
  /* } else if (removeLastDash($i18nPath($route.path)) === removeLastDash($i18nPath('/'))) {
    classes.push('bg-opacity-20')
  } */
  if (route.path.includes('/products/')) {
    classes.push('md:absolute md:mb-[-110px] bg-opacity-0')
  } else {
    classes.push('md:fixed')
  }

  if (state.scrollPosition >= 40 && !state.isProductPage) {
    classes.push('md:fixed bg-black bg-opacity-70 backdrop-blur-lg pt-0')
  } else {
    classes.push('pt-4')
  }
  return classes
})

const newTextClass = computed(() => {
  if (store.locale === 'el' || store.locale === 'ar') {
    return 'xl:text-[20px] text-[16px]'
  } else {
    return 'xl:text-[28px] text-[24px]'
  }
})
const dynamicLinkClass = computed(() => {
  return state.isProductPage ? 'nav-product' : ''
})

const products = store.products
const categories = store.categories
const currentSite = store.currentSite
const currentSiteSettings = store.currentSiteSettings

// watch(
//   router.currentRoute,
//   (newValue, oldValue) => {
//     if (newValue.name == 'country-products-slug') {
//       state.isProductPage = true
//     } else {
//       state.isProductPage = false
//     }
//   },
//   { deep: true }
// )

onBeforeMount(() => {
  if (!import.meta.browser) {
    return
  }
  state.isProductPage = window.location.href.includes('/products/')
})
onMounted(() => {
  if (!import.meta.browser) {
    return
  }
  if (import.meta.client) {
    nuxtApp.hook('page:loading:end', () => {
      if (router.currentRoute.value.name == 'country-products-slug') {
        state.isProductPage = true
      } else {
        state.isProductPage = false
      }
    })
  }
  window.addEventListener('scroll', updateScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', updateScroll)
})

function updateScroll() {
  state.scrollPosition = window.scrollY
}

function changeColor(color) {
  color.value = color
}
</script>

<style lang="postcss">
.mm-spn:dir(rtl) li:before {
  margin-right: 84%;
}
.directionltr {
  direction: ltr;
}
.directionrtl {
  direction: rtl;
}
.nav-link {
  @apply block z-10 text-2xl font-light hover:no-underline focus:no-underline text-white hover:text-white focus:outline-none relative;

  &.nav-product {
    @apply text-white;
    &.hover-effect {
      &::before {
        @apply bg-white;
      }
    }
  }

  &.nuxt-link-exact-active {
    @apply cursor-default;

    &.hover-effect::before {
      transform: scaleX(1);
    }
  }
  &.router-link-exact-active {
    @apply cursor-default;

    &.hover-effect::before {
      transform: scaleX(1);
    }
  }

  &.hover-effect::before,
  &.hover-effect::after {
    content: '';
    position: absolute;
    transition: transform 0.3s ease;
  }

  &.hover-effect {
    &::before {
      @apply bg-white;

      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
    }
  }

  &.hover-effect:hover::before {
    transform: scaleX(1);
  }
}
</style>
