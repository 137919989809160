<template>
  <DrawerModal>
    <section>
      <div class="flex flex-col h-screen font-ff-condensed">
        <div
          :class="[hasErrors ? 'mb-[22px] md:mb-3' : 'mb-3', 'px-[30px] pt-10']"
        >
          <h2
            class="2xl:text-3xl md:text-[26px] font-normal text-2xl font-[#0F0F0F]"
          >
            {{ $t('common.reset_account_password') }}
          </h2>
          <p class="text-[#404040] mt-2 text-sm xl:text-base">
            <span class="font-ff-condensed">
              {{ $t('common.enter_a_new_password') }}
            </span>
            <br />
            <span class="font-ff-condensed-pro">
              {{ email }}
            </span>
          </p>
        </div>

        <!-- Error -->
        <div class="px-[30px]">
          <div
            v-if="hasErrors && message"
            class="px-4 py-3 bg-red-50 rounded-[10px]"
          >
            <div class="flex items-center">
              <IconsInfoCircle class="mr-2" />
              <div class="text-sm">
                {{ message }}
              </div>
            </div>
          </div>
        </div>

        <div
          :class="[
            hasErrors
              ? 'xl:pt-6 md:pt-5 pt-[30px]'
              : 'pt-[38px] md:pt-10 xl:pt-[64px]',
            'px-[30px] overflow-y-scroll no-scrollbar flex-1 mb-[240px] md:mb-0',
          ]"
        >
          <form method="POST">
            <div class="mb-6">
              <label
                for="password"
                class="text-sm text-[#0F0F0F] font-medium font-ff-condensed"
              >
                {{ $t('common.set_new_password') }}
              </label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <input
                  id="password"
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  :placeholder="$t('common.password_placeholder')"
                  :class="hasErrors ? 'border-red-500' : 'border-gray-300'"
                  name="password"
                  class="text-lg block w-full py-3 px-4 rounded-[10px]"
                />
                <div
                  class="absolute inset-y-0 flex items-center cursor-pointer end-0 pe-3"
                >
                  <svg
                    @click="showPassword = !showPassword"
                    class="w-5 h-5 text-[#0F0F0F]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <template v-if="!showPassword">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </template>
                    <template v-else>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                      />
                    </template>
                  </svg>
                </div>
              </div>
              <div v-if="hasErrors" class="mt-1.5 text-sm text-red-500">
                <p
                  v-for="(errorsArray, field) in errorsObj.password"
                  :key="field"
                >
                  {{ errorsArray }}
                </p>
              </div>
            </div>
            <div>
              <label
                for="repeatPassword"
                class="text-sm text-[#0F0F0F] font-medium font-ff-condensed"
              >
                {{ $t('common.confirm_password') }}
              </label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <input
                  id="repeatPassword"
                  v-model="password_confirmation"
                  :type="showRepeatPassword ? 'text' : 'password'"
                  :placeholder="$t('common.reenter_password')"
                  :class="hasErrors ? 'border-red-500' : 'border-gray-300'"
                  name="repeatPassword"
                  class="text-lg block w-full py-3 px-4 rounded-[10px]"
                />
                <div
                  class="absolute inset-y-0 flex items-center cursor-pointer end-0 pe-3"
                >
                  <svg
                    @click="showRepeatPassword = !showRepeatPassword"
                    class="w-5 h-5 text-[#0F0F0F]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <template v-if="!showRepeatPassword">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </template>
                    <template v-else>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="{2}"
                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                      />
                    </template>
                  </svg>
                </div>
              </div>
              <div v-if="hasErrors" class="mt-1.5 text-sm text-red-500">
                <p
                  v-for="(errorsArray, field) in errorsObj.password"
                  :key="field"
                >
                  {{ errorsArray }}
                </p>
              </div>
            </div>
          </form>
        </div>

        <div
          class="pt-2 px-[30px] absolute left-0 right-0 bg-white bottom-10 w-full"
        >
          <button
            :disabled="loading"
            @click.prevent="onSubmit"
            type="submit"
            class="w-full bg-primary-500 font-ff-condensed-pro font-medium rounded-[10px] text-white py-[10px] md:py-2 md:px-[30px] xl:px-[82px] disabled:bg-primary-300 text-base disabled:cursor-not-allowed"
          >
            {{ $t('common.reset_password') }}
          </button>
        </div>
      </div>
    </section>
  </DrawerModal>
</template>

<script setup>
import { useModal, useVfm } from 'vue-final-modal'
import SignInModal from './SignInModal.vue'
import IconsInfoCircle from '~/components/icons/InfoCircleIcon.vue'

definePageMeta({
  middleware: 'guest',
})

const props = defineProps({
  token: {
    type: String,
    default: '',
  },
  email: {
    type: String,
    default: '',
  },
})
const nuxtApp = useNuxtApp()
const router = useRouter()
const vfm = useVfm()

const password = ref('')
const password_confirmation = ref('')
const errorsObj = ref({})

const message = ref(null)
const loading = ref(false)
const showPassword = ref(false)
const showRepeatPassword = ref(false)

const hasErrors = computed(() => {
  return Object.keys(errorsObj.value).length > 0
})

async function onSubmit() {
  loading.value = true
  try {
    await nuxtApp.$api('api/auth/password/reset', {
      method: 'post',
      body: {
        email: props.email,
        password: password.value,
        password_confirmation: password_confirmation.value,
        token: props.token,
      },
    })

    nuxtApp.$toast.success('Account password reset successfully.')
    router.push({ path: nuxtApp.$i18nPath('/') })

    vfm.closeAll()
    const { open, close } = useModal({
      component: SignInModal,
      attrs: {
        accountEmail: email.value,
      },
    })

    open()
  } catch (error) {
    console.error(error, 'error')

    if (error && !error._data) {
      throw error
    } else {
      errorsObj.value = error._data.errors
      message.value = error._data.message
    }

    setTimeout(() => {
      loading.value = false
    }, 300)
  }
}
</script>
