<template>
  <client-only>
    <div v-click-away="away">
      <div class="relative z-[100]">
        <div
          :class="[
            scrollPosition >= 80 && isOpen !== true ? 'scrolledHover' : '',
            isProductPage ? 'nav-product' : '',
          ]"
          @click="onHoverOpen"
        >
          <slot />
        </div>
      </div>
      <div v-show="isOpen">
        <div
          class="bg-[#fafafa] absolute inset-x-0 max-h-screen flyout-drawer z-50 top-[4.5rem]"
        >
          <div class="container relative">
            <div
              class="absolute z-50 top-8 right-[4.5rem] rtl:right-auto rtl:left-[4.5rem] hover:opacity-60"
            >
              <button @click="away">
                <CloseIcon class="h-9 w-h-9 text-[#807E7E]" />
              </button>
            </div>
            <!---------------- product list menu --------------------->
            <nav
              v-if="isProduct"
              class="flex flex-row min-h-[500px] max-h-full py-8 sm:py-12"
            >
              <!--main nav -->
              <div class="h-full md:w-60 lg:w-64">
                <transition-group
                  class="pr-3 space-y-4 lg:space-y-5"
                  name="flyout-item-primary"
                  tag="ul"
                >
                  <template v-if="products && products.length">
                    <li
                      v-for="(cat, cIndex) in products"
                      v-show="isOpen"
                      :key="`key-${cIndex}`"
                      :style="{ '--i': cIndex }"
                    >
                      <a
                        href="#"
                        class="inline-flex link items-center gap-3 px-3 py-2 -m-3 text-2xl lg:text-3xl font-medium tracking-wide text-[#807E7E] uppercase break-words break-all transition duration-150 ease-in-out hover:text-primary-500 hover:no-underline cursor-pointer"
                        :class="{
                          'link-selected':
                            state.menuSelected &&
                            state.menuSelected.original_name ===
                              cat.original_name,
                        }"
                        @mouseenter="onChangeMenu(cat)"
                        @touchstart="onChangeMenu(cat)"
                        @touchend="(e) => e.preventDefault()"
                        v-text="cat.name ? cat.name : cat.original_name"
                      />
                    </li>
                  </template>
                  <template v-else>
                    <li
                      v-for="index in 4"
                      :key="index"
                      class="skeleton-placeholder"
                    >
                      <ContentLoader
                        class="h-[20px] w-full"
                        height="20"
                        :speed="2"
                        view-box="0 0 30 20"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="5"
                          ry="5"
                          width="100%"
                          height="20"
                        />
                      </ContentLoader>
                    </li>
                  </template>
                </transition-group>
              </div>

              <!-- secondary nav -->
              <template v-if="state.menuSelected">
                <div
                  v-for="(products, menuTitle, realIndex) in state.menuSelected
                    .products"
                  :key="`key-${menuTitle}-${state.menuSelected.name.replace(
                    /\s/g,
                    ''
                  )}`"
                  :style="{
                    '--i': realIndex,
                  }"
                  class="block w-56 px-3 text-center border-l rtl:border-r rtl:border-l-0 lg:w-60 flyout-secondary-nav-animation"
                >
                  <ul class="space-y-2">
                    <li class="mb-3">
                      <h4
                        class="mb-0 text-xl font-bold font-ff-condensed text-capitalize"
                      >
                        {{ menuTitle ? menuTitle : 'All' }}
                      </h4>
                    </li>
                    <li
                      v-for="(product, prdIndex) in products"
                      :key="`key-${prdIndex}`"
                    >
                      <nuxt-link
                        :to="$i18nPath(`/products/${product.slug}`)"
                        class="inline w-full link font-light text-[#807E7E] text-xl transition duration-150 ease-in-out uppercase break-words break-all"
                        :class="{
                          'link-selected': state.itemSelected
                            ? state.itemSelected.id == product.id
                            : prdIndex == 0
                            ? (state.itemSelected = product)
                            : false,
                        }"
                        @mouseenter.native="state.itemSelected = product"
                        @touchstart.native="state.itemSelected = product"
                        @touchend.native="(e) => e.preventDefault()"
                        v-text="product.name"
                      />
                    </li>
                  </ul>
                </div>
              </template>

              <!-- Product image -->
              <template v-if="state.itemSelected">
                <div class="flex-1 px-5 text-center ltr:border-l rtl:border-r">
                  <div class="max-w-[450px]">
                    <nuxt-link
                      :to="$i18nPath(`/products/${state.itemSelected.slug}`)"
                      :prefetch="false"
                      class="flex items-center justify-center mt-12"
                      style="min-height: 253px"
                    >
                      <ImgLazyLoader
                        v-if="state.itemSelected && state.itemSelected.image"
                        :src="state.itemSelected.image"
                        class="h-auto max-w-full"
                        image-class="h-auto max-w-full"
                        loading="lazy"
                      />
                      <div v-else class="text-[#d7d7d7] h-40 w-40">
                        <img
                          src="~/assets/image/no-products.png"
                          class="w-full h-auto"
                        />
                      </div>
                    </nuxt-link>

                    <div class="mt-4">
                      <Btn
                        size="base"
                        @click="
                          onClickLink(
                            $i18nPath(`/products/${state.itemSelected.slug}`)
                          )
                        "
                        rounded
                        block
                      >
                        {{ $t('common.go_to_product') }}
                      </Btn>
                    </div>
                  </div>
                </div>
              </template>
            </nav>

            <!----------------- categories list menu ----------------->
            <nav
              v-else
              class="flex flex-row min-h-[500px] max-h-[80vh] py-8 sm:py-12"
            >
              <!--main nav -->
              <div class="w-60">
                <transition-group
                  class="pr-3 space-y-2 lg:space-y-3"
                  name="flyout-item-primary"
                  tag="ul"
                >
                  <li
                    v-for="(cat, cIndex) in categories"
                    v-show="isOpen"
                    :key="`key-${cIndex}`"
                    class="table"
                    :style="{ '--i': cIndex }"
                  >
                    <a
                      class="flex items-center link gap-3 px-3 py-2 -m-3 text-2xl lg:text-3xl font-medium tracking-wide text-[#807E7E] uppercase break-words break-all transition duration-150 ease-in-out hover:text-primary-500 hover:no-underline cursor-pointer"
                      :class="{
                        'link-selected':
                          state.menuSelected &&
                          state.menuSelected.original_name ===
                            cat.original_name,
                      }"
                      @mouseenter="onChangeMenu(cat)"
                      @touchstart="onChangeMenu(cat)"
                      @touchend="(e) => e.preventDefault()"
                      v-text="cat.name"
                    />
                  </li>
                </transition-group>
              </div>

              <!-- secondary nav -->
              <template v-if="state.menuSelected">
                <div
                  class="block pl-6 pr-8 border-l w-60 flyout-secondary-nav-animation"
                >
                  <ul class="space-y-2">
                    <li
                      v-for="(category, prdIndex) in state.menuSelected
                        .children"
                      :key="`key-${prdIndex}`"
                      class="table"
                    >
                      <nuxt-link
                        :to="
                          $i18nPath(
                            `/search/equipment?category=${category.original_name}`
                          )
                        "
                        class="inline w-full link text-xl font-light text-[#807E7E] transition duration-150 ease-in-out"
                        :class="{
                          'link-selected': state.itemSelected
                            ? state.itemSelected.id == category.id
                            : false,
                        }"
                        @mouseenter.native="state.itemSelected = category"
                        @touchstart.native="state.itemSelected = category"
                        @touchend.native="(e) => e.preventDefault()"
                        v-text="category.name"
                      />
                    </li>
                  </ul>
                </div>
              </template>

              <!-- Category image -->
              <template v-if="state.itemSelected">
                <div class="flex-1 px-6 text-center border-l">
                  <div class="max-w-[450px]">
                    <nuxt-link
                      :to="
                        $i18nPath(
                          `/search/equipment?category=${state.itemSelected.original_name}`
                        )
                      "
                      :prefetch="false"
                      class="flex items-center justify-center"
                      style="min-height: 253px"
                    >
                      <img
                        v-if="state.itemSelected && state.itemSelected.image"
                        :src="state.itemSelected.image"
                        class="h-auto max-w-full"
                        loading="lazy"
                      />
                      <div v-else class="text-[#d7d7d7] h-40 w-40">
                        <img
                          src="~/assets/image/no-products.png"
                          class="w-full h-auto"
                        />
                      </div>
                    </nuxt-link>
                  </div>
                </div>
              </template>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </client-only>
</template>

<script setup>
import { ContentLoader } from 'vue-content-loader'
// import { mixin as clickaway } from "vue3-click-away";
import CloseIcon from '@/assets/svg/heroicons/x-circle.svg?component'
import AngleDown from '@/assets/svg/heroicons/angle-down.svg?component'
import AngleUp from '@/assets/svg/heroicons/angle-up.svg?component'
import { useMainStore } from '@/store/index'

const props = defineProps({
  color: {
    type: String,
    required: false,
    default: 'white',
  },
  linkTitle: {
    type: String,
    required: false,
    default: '',
  },
  isProduct: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const store = useMainStore()
const emit = defineEmits()
const router = useRouter()
const nuxtApp = useNuxtApp()

const isOpen = ref(false)
const scrollPosition = ref(0)
const isProductPage = ref(false)
let state = reactive({
  menuSelected: null,
  itemSelected: null,
})

const $i18nPath = nuxtApp.$i18nPath
const $formatPrice = nuxtApp.$formatPrice
const products = store.products
const categories = store.categories

watch(
  router.currentRoute,
  (newValue, oldValue) => {
    away()

    if (newValue.name == 'country-products-slug') {
      isProductPage.value = true
    } else {
      isProductPage.value = false
    }
  },
  { deep: true }
)

watch(isOpen, (newValue, oldValue) => {
  if (newValue) {
    state.menuSelected = products.length > 0 && products[0]

    state.itemSelected =
      state.menuSelected &&
      Object.keys(state.menuSelected.products).length > 0 &&
      state.menuSelected.products[
        Object.keys(state.menuSelected.products)[0]
      ][0]
  }
})

onMounted(() => {
  isProductPage.value = window.location.href.includes('/products/')
  window.addEventListener('scroll', updateScroll)
  if (process.browser) {
    heightCheck()
  }
})

onUnmounted(() => {
  window.removeEventListener('scroll', updateScroll)
})

const onClickLink = (route) => {
  isOpen.value = false
  router.push(route)
}

const onChangeMenu = (cat) => {
  const timeout = setTimeout(() => {
    const box = document.querySelector('.box')
    // Check height initially
    checkHeight(box)
    clearTimeout(timeout)
  }, 100)
  state.menuSelected = cat
  state.itemSelected = null
}
const heightCheck = () => {
  const box = document.querySelector('.box')
  if (!box) return

  // Check height initially
  checkHeight(box)
}

const checkHeight = (box = null) => {
  if (!box) return false
  if (!isOpen.value) return false
  const computedStyle = getComputedStyle(box).maxHeight
  if (!computedStyle || computedStyle == 'none') return false

  const maxHeight = parseFloat(computedStyle.split('px').join(''))
  if (isNaN(maxHeight)) return false

  if (maxHeight == NaN || maxHeight == 'NaN') {
    return false
  }
  const boxScroll = document.querySelector('.scroll-height')

  if (!boxScroll) return false
  if (boxScroll.scrollHeight >= maxHeight) {
    box.classList.add('border-bottom')
  } else {
    box.classList.remove('border-bottom')
  }
}

function away() {
  // document.body.style.overflowY = 'visible'
  isOpen.value = false
  reset()
}

function onHoverOpen() {
  // document.body.style.overflowY = 'hidden'
  emit('color', 'black')
  isOpen.value = !isOpen.value

  if (!isOpen.value) {
    // document.body.style.overflowY = 'visible'
    reset()
  }
}

function reset() {
  state.menuSelected = null
  state.itemSelected = null
  emit('color', 'white')
}

function updateScroll() {
  scrollPosition.value = window.scrollY
}
</script>

<style lang="postcss">
.flyout-drawer {
  @apply bg-[#fafafa] shadow-lg;
  height: auto;

  .link {
    &:hover,
    &.link-selected {
      @apply text-primary no-underline opacity-100;
    }
  }
}

.flyout-drawer::before {
  @apply relative inset-0 w-full h-full;

  content: '';
  animation-name: flyout-drawer-slide-down;
  animation-duration: 0.75s;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes flyout-drawer-slide-down {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.flyout-item-primary-enter-active,
.flyout-item-primary-leave-active {
  animation-name: flyout-item-primary-animation;
  animation-delay: calc(0.15s * var(--i));
  animation-duration: 0.15s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  opacity: 0;
  transform: translateY(66%);
}

@keyframes flyout-item-primary-animation {
  from {
    opacity: 0;
    transform: translateY(66%);
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes flyout-secondary-nav-animation {
  0% {
    transform: translateX(-30px);
    opacity: 0;
  }

  67% {
    transform: translateX(0);
    opacity: 0.67;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.flyout-secondary-nav-animation {
  animation-name: flyout-secondary-nav-animation;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-delay: calc(0.25s * var(--i));
}
</style>
